/* finally, import Bootstrap to set the changes! */
:root {
  font-size: 16px;
  font-family: "Open Sans";
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --transition-speed: 600ms; }

html {
  scroll-behavior: smooth; }

body {
  color: black;
  background-color: #f4f5f9;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Open Sans"; }

body::-webkit-scrollbar {
  width: 0.25rem; }

body::-webkit-scrollbar-track {
  background: #1e1e24; }

body::-webkit-scrollbar-thumb {
  background: #6649b8; }

main {
  margin-left: 17rem; }
  main section {
    padding: 5rem; }

.navbar {
  position: fixed;
  background-color: var(--bg-primary);
  transition: margin 600ms ease;
  overflow: scroll; }

.navbar::-webkit-scrollbar {
  display: none; }

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%; }

.nav-item {
  width: 100%; }

/* .nav-item:last-child {
  margin-top: auto;
} */
.nav-link {
  display: flex;
  align-items: center;
  height: 5rem;
  color: var(--text-primary);
  text-decoration: none;
  filter: grayscale(100%) opacity(0.7);
  transition: var(--transition-speed); }

.nav-link:hover {
  filter: grayscale(0%) opacity(1);
  background: var(--bg-secondary);
  color: var(--text-secondary); }

.nav-link.active {
  filter: grayscale(0%) opacity(1);
  background: var(--bg-secondary);
  color: var(--text-secondary);
  font-weight: bolder;
  font-size: larger; }

.link-text {
  display: none;
  margin-left: 1rem; }

.nav-link svg {
  width: 2rem;
  min-width: 2rem;
  margin: 0 1.5rem; }

.fa-primary {
  color: #ff7eee; }

.fa-secondary {
  color: #df49a6; }

.fa-primary,
.fa-secondary {
  transition: var(--transition-speed); }

.logo {
  font-weight: bold;
  text-transform: uppercase;
  margin-top: auto;
  text-align: center;
  color: var(--text-secondary);
  background: var(--bg-secondary);
  font-size: 1.5rem;
  letter-spacing: 0.3ch;
  width: 100%; }

.logo svg {
  transform: rotate(0deg);
  transition: var(--transition-speed); }

.logo-text {
  display: inline;
  position: absolute;
  left: -999px;
  transition: var(--transition-speed); }

.navbar .expand .logo svg {
  transform: rotate(-180deg); }

/* Small screens */
@media only screen and (max-width: 600px) {
  .navbar {
    bottom: 0;
    width: 100vw;
    height: 5rem;
    z-index: 2000; }
  .logo {
    display: none; }
  .navbar-nav {
    flex-direction: row;
    width: 100%; }
  .nav-link {
    justify-content: center; }
  main {
    margin: 0; }
    main section {
      padding: 0.5rem; }
  .card:last-child {
    margin-bottom: 3rem; }
  .featurette {
    margin: 1rem 0;
    /* Space out the Bootstrap <hr> more */ }
  #skills h2 {
    margin-left: 0.5rem; } }

/* Large screens */
@media only screen and (min-width: 600px) {
  .navbar {
    top: 0;
    height: 100vh; }
  .navbar .expand {
    width: 17rem; }
  .navbar .expand .link-text {
    display: inline; }
  .navbar .expand .logo svg {
    margin-left: 13rem; }
  .navbar .expand .logo-text {
    left: 0px; }
  .featurette {
    margin-right: 5rem;
    /* Space out the Bootstrap <hr> more */ }
  .lead:first-child {
    margin-top: 5rem; } }

.dark {
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418; }

.light {
  --text-primary: #1f1f1f;
  --text-secondary: #000000;
  --bg-primary: #ffffff;
  --bg-secondary: #e4e4e4; }

.solar {
  --text-primary: #576e75;
  --text-secondary: #35535c;
  --bg-primary: #fdf6e3;
  --bg-secondary: #f5e5b8; }

.theme-icon {
  display: none; }

.dark #darkIcon {
  display: block; }

.light #lightIcon {
  display: block; }

.solar #solarIcon {
  display: block; }

/* New CSS */
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  /* font-size: 3rem; */
  color: #353535;
  box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem, rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  transition: all 500ms;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  .card .card-img-top {
    height: 360px;
    width: 100%;
    display: block;
    border-style: none;
    padding: 2rem; }
  .card .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
    border-top: outset; }
    .card .card-body .card-title {
      font-size: 1.25rem;
      margin-bottom: 0.75rem;
      font-weight: bold;
      /* font-family: inherit;
      line-height: 1.2;
      color: inherit; */ }
    .card .card-body .card-text {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px; }
  .card .card-footer {
    padding: 2rem 0.5rem;
    background-color: #fff;
    border: none; }
    .card .card-footer img {
      padding: 0.25rem;
      width: 5rem;
      height: 2.5rem; }

/* .card:hover {
  box-shadow: rgba(2, 8, 20, 0.1) 0px 0.35em 1.175em,
    rgba(2, 8, 20, 0.08) 0px 0.175em 0.5em;
  transform: translateY(-3px) scale(1.1);
} */
.basic-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); }

.btn-primary {
  word-wrap: break-word;
  box-sizing: border-box;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  cursor: pointer; }

/* Featurettes
------------------------- */
.featurette-divider {
  margin: 5rem 0;
  /* Space out the Bootstrap <hr> more */ }

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  /* rtl:remove */
  letter-spacing: -0.05rem; }

/* RESPONSIVE CSS
-------------------------------------------------- */
@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4; }
  .featurette-heading {
    font-size: 50px; } }

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem; } }

.cover-container {
  max-width: 49em;
  margin-top: 1em;
  margin-bottom: 6em; }

.cover-heading {
  font-weight: bold;
  margin-bottom: 3rem; }

.dynamic-typist {
  height: 3em; }

.section-heading {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  font-size: 4rem;
  margin-bottom: 3rem; }

#about {
  margin-top: 15rem; }

section:nth-child(even) {
  background-color: #e3e3e3; }

.contact-btn {
  margin: 0.5rem;
  word-wrap: break-word;
  box-sizing: border-box;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #050710;
  background-color: transparent;
  cursor: pointer; }

.contact-btn:hover {
  text-decoration: none;
  color: #fff;
  background-color: #050710;
  border-color: #050710; }

.text-large {
  font-size: x-large; }

.social-icon {
  margin: 1rem; }

.lead {
  font-size: 1.5rem;
  font-weight: 400; }

.tech-label {
  height: 2.5rem;
  margin: 0.5rem; }

.skill-set {
  margin: 3rem 0; }

.mouse_scroll {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 3rem; }

.m_scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid var(--bg-primary);
  border-bottom: 2px solid var(--bg-primary);
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px; }

.unu {
  margin-top: 1px; }

.unu,
.doi,
.trei {
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
  animation: mouse-scroll 1s infinite; }

.unu {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  animation-delay: alternate; }

.doi {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  animation-delay: 0.2s;
  animation-direction: alternate;
  margin-top: -6px; }

.trei {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  animation-delay: 0.3s;
  animation-direction: alternate;
  margin-top: -6px; }

.mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 2px solid;
  border-color: var(--bg-primary);
  top: 170px; }

.wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: var(--bg-primary);
  position: relative;
  height: 4px;
  width: 4px;
  border: 2px solid;
  border-color: var(--bg-primary);
  -webkit-border-radius: 8px;
  border-radius: 8px; }

.wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite; }

@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px); } }

@-moz-keyframes mouse-wheel {
  0% {
    top: 1px; }
  25% {
    top: 2px; }
  50% {
    top: 3px; }
  75% {
    top: 2px; }
  100% {
    top: 1px; } }

@-o-keyframes mouse-wheel {
  0% {
    top: 1px; }
  25% {
    top: 2px; }
  50% {
    top: 3px; }
  75% {
    top: 2px; }
  100% {
    top: 1px; } }

@keyframes mouse-wheel {
  0% {
    top: 1px; }
  25% {
    top: 2px; }
  50% {
    top: 3px; }
  75% {
    top: 2px; }
  100% {
    top: 1px; } }

@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@-moz-keyframes mouse-scroll {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@-o-keyframes mouse-scroll {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes mouse-scroll {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }
